<template>
  <div class="about">
    <div class="site-content_info">
      <div class="content-warp">
        <div class="about-site about-info markdown-body">
          <section-title :title="title"></section-title>
          <Markdown :content="content"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Markdown from '@/components/Markdown.vue'
import 'github-markdown-css/github-markdown.css'
import {fetchBlogInfo} from "@/api";
import sectionTitle from "@/components/section-title";
export default {
  name: 'Home',
  components: {
    Markdown,
    sectionTitle
  },
  data: () => ({
    content: '',
    title: ''
  }),
  created() {
    this.blogInfo()
  },
  methods: {
    blogInfo: function () {
      const routerParams = this.$route.query.id
      fetchBlogInfo(routerParams).then(result => {
        this.content = result.data.text
        this.title = result.data.title
      })
    }
  }
}
</script>
<style scoped lang="less">
.about {
  padding-top: 40px;
}
.content-warp {
  margin-top: 80px;

  .about-info {
    margin: 30px 0;

    span {
      color: red;
      margin-right: 10px;
    }
  }
}
.site-content_info {
  max-width: 65%;
  margin: 0 auto;
  padding: 0 10px;
}
/*******/
@media (max-width: 800px) {
  .content-warp {
    margin-top: 0;
  }
}
</style>
